import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faBriefcase,
  faWarehouse,
  faCalculator,
  faWrench,
  faUsers,
  faChartLine,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";

const sidebar_options = [
  {
    key: "dashboard",
    label: "dashboard",
    icon: <FontAwesomeIcon icon={faChartLine} />,
    permission_required : false
  },
  {
    key: "inventory",
    label: "inventory",
    icon: <FontAwesomeIcon icon={faWarehouse} />,
    is_root : true,
    permission_required : true,
    children: [
      {
        key: "property-offers",
        label: "property_offers",
        icon: "",
        module : "inventory",
        permission_required : true,
      },
      {
        key: "inventory-list",
        label: "inventory_list",
        icon: "",
        module : "inventory",
        permission_required : true,
      },
      {
        key: "projects",
        label: "projects",
        icon: "",
        module : "inventory",
        permission_required : true,
      },
      {
        key: "selling-price",
        label: "selling_price",
        icon: "",
        module : "inventory",
        permission_required : true,
      },
      {
        key: "appraisal-value",
        label: "appraisal_value",
        icon: "",
        module : "inventory",
        permission_required : true,
      },
      {
        key: "site-map",
        label: "project_site_map",
        icon: "",
        module : "inventory",
        permission_required : true,
      },
      {
        key: "change-item-status",
        label: "change_item_status",
        icon: "",
        module : "inventory",
        permission_required : true,
      },
      {
        key: "business-plan",
        label: "business_plan",
        icon: "",
        module : "inventory",
        permission_required : true,
      },
      {
        key: "record-approvals",
        label: "record_approvals",
        icon: "",
        module : "inventory",
        permission_required : true,
      },
      {
        key: "inventory-setup",
        label: "setup",
        icon: "",
        module : "inventory",
        permission_required : true,
        children: [
          {
            key: "stages-by-project-classification",
            label: "stages_by_project_classification",
            icon: "",
            module : "inventory",
            permission_required : true,
          },
          {
            key: "market-segment-matrix",
            label: "market_segment_matrix",
            icon: "",
            module : "inventory",
            permission_required : true,
          },
          
          {
            key: "inventory-checklist",
            label: "inventory_checklist",
            icon: "",
            module : "inventory",
            permission_required : true,
          },
          {
            key: "price-factors",
            label: "price_factors",
            icon: "",
            module : "inventory",
            permission_required : true,
          },
          {
            key: "lot-price-parameters",
            label: "lot_price_parameters",
            icon: "",
            module : "inventory",
            permission_required : true,
          }
        ]
      }
    ],
  },
  {
    key : "sales",
    label : "sales",
    icon: <FontAwesomeIcon icon={faBriefcase}/>,
    permission_required : true,
    is_root : true,
    children : [
      {
        key: "customer-information",
        label: "customer_information",
        icon: "",
        module : "sales",
        permission_required : true,
      },
      {
        key: "seller-information",
        label: "seller_information",
        icon: "",
        module : "sales",
        permission_required : true,
      },
      {
        key: "sample-computation",
        label: "sample_computation",
        icon: "",
        module : "sales",
        permission_required : true,
      },
      {
        key: "reservation-entry",
        label: "reservation_entry",
        icon: "",
        module : "sales",
        permission_required : true,
      },
      {
        key: "credit-approval",
        label: "credit_approval",
        icon: "",
        module : "sales",
        permission_required : true,
      },
      {
        key: "document-printing",
        label: "document_printing",
        icon: "",
        module : "sales",
        permission_required : true,
      },
      {
        key: "record-approvals",
        label: "record_approvals",
        icon: "",
        module : "sales",
        permission_required : true,
      },
      {
        key: "sales-report",
        label: "report",
        icon: "",
        permission_required : true,
        module : "sales",
        children: [
          {
            key: "price-list",
            label: "price_list",
            icon: "",
            module : "sales",
            permission_required : true,
          },
        ]
      },
      {
        key: "sales-setup",
        label: "setup",
        icon: "",
        permission_required : true,
        module : "sales",
        children: [
          {
            key: "reservation-requirements",
            label: "reservation_requirements",
            icon: "",
            module : "sales",
            permission_required : true,
          },
          {
            key: "seller-requirements",
            label: "seller_requirements",
            icon: "",
            module : "sales",
            permission_required : true,
          },
          {
            key: "payment-terms",
            label: "payment_terms",
            icon: "",
            module : "sales",
            permission_required : true,
          },
          {
            key: "promos-and-discounts",
            label: "promos_and_discounts",
            icon: "",
            module : "sales",
            permission_required : true
          },
          {
            key: "disclaimer-statement",
            label: "disclaimer_statement",
            icon: "",
            module : "sales",
            permission_required : false
          },
          {
            key: "mobile-app-announcements",
            label: "mobile_app_announcements",
            icon: "",
            module : "sales",
            permission_required : false
          },
          {
            key: "mobile-app-faq",
            label: "mobile_app_faq",
            icon: "",
            module : "sales",
            permission_required : false
          },
          {
            key: "payment-term-discounts",
            label: "payment_term_discounts",
            icon: "",
            module : "sales",
            permission_required : false
          }
        ]
      }
    ]
  },
  {
    key : "accounting",
    label : "accounting",
    icon: <FontAwesomeIcon icon={faCalculator}/>,
    permission_required : true,
    is_root : true,
    children : [
      {
        key: "employee-information",
        label: "employee_information",
        icon: "",
        module : "accounting",
        permission_required : true,
      },
      {
        key: "supplier-information",
        label: "supplier_information",
        icon: "",
        module : "accounting",
        permission_required : true,
      },
      {
        key: "item-master",
        label: "item_master",
        icon: "",
        module : "accounting",
        permission_required : true,
      },
      {
        key: "item-purchase",
        label: "item_purchase",
        icon: "",
        module : "accounting",
        permission_required : true,
      },
      {
        key: "reservation-payment-entry",
        label: "reservation_payment_entry",
        icon: "",
        module : "accounting",
        permission_required : true,
      },
      {
        key: "record-approvals",
        label: "record_approvals",
        icon: "",
        module : "accounting",
        permission_required : true,
      },
    ]
  },
  {
    key : "collection",
    label : "collection",
    icon: <FontAwesomeIcon icon={faCoins}/>,
    permission_required : true,
    is_root : true,
    children : [
      {
        key: "cash-payments",
        label: "cash_payment",
        icon: "",
        module : "collection",
        permission_required : true,
      },
      {
        key: "check-acceptances",
        label: "check_acceptance",
        icon: "",
        module : "collection",
        permission_required : true,
      },
      {
        key: "check-taggings",
        label: "check_tagging",
        icon: "",
        module : "collection",
        permission_required : true,
      },
      {
        key: "collection-taggings",
        label: "collection_tagging",
        icon: "",
        module : "collection",
        permission_required : true,
      },
      {
        key: "customer-ledger",
        label: "customer_ledger",
        icon: "",
        module : "collection",
        permission_required : true,
      },
      {
        key: "change-payment-details",
        label: "change_payment_details",
        icon: "",
        module : "collection",
        permission_required : true,
      },
      {
        key: "transfer-unit",
        label: "transfer_of_unit",
        icon: "",
        module : "collection",
        permission_required : true,
      },
      {
        key: "transfer-buyer",
        label: "transfer_of_buyer",
        icon: "",
        module : "collection",
        permission_required : true,
      },
      {
        key: "record-approvals",
        label: "record_approvals",
        icon: "",
        module : "collection",
        permission_required : true,
      },
      {
        key: "collection-setup",
        label: "setup",
        icon: "",
        permission_required : true,
        module : "collection",
        children: [
          {
            key: "bank-accounts",
            label: "bank_account",
            icon: "",
            module : "collection",
            permission_required : true,
          },
          {
            key: "check-statuses",
            label: "check_status",
            icon: "",
            module : "collection",
            permission_required : true,
          },
          {
            key: "collection-statuses",
            label: "collection_status",
            icon: "",
            module : "collection",
            permission_required : true,
          },
          {
            key: "mri",
            label: "mri_setup",
            icon: "",
            module : "collection",
            permission_required : true,
          },
        ]
      }
    ]
  },
  {
    key: "setup",
    label: "setup",
    icon: <FontAwesomeIcon icon={faWrench} />,
    permission_required : true,
    is_root : true,
    children: [
      {
        key: "dropdown-records",
        label: "dropdown_records",
        icon: "",
        module : "setup",
        permission_required : true,
      },
      {
        key: "record-approval-assignment",
        label: "record_approval_assignment",
        icon: "",
        module : "setup",
        permission_required : true,
      },
    ],
  },
  {
    key: "settings",
    label: "settings",
    icon: <FontAwesomeIcon icon={faCogs} />,
    is_root : true,
    permission_required : true,
    children: [
      // {
      //   key: "users",
      //   label: "users",
      //   icon: "",
      //   url: app_config.userManagementURL,
      // },
      {
        key: "user-accounts",
        label: "user_accounts",
        icon: "",
        module : "settings",
        permission_required : true,
      },
      {
        key: "user-roles",
        label: "user_roles",
        icon: "",
        module : "settings",
        permission_required : true,
      },
      {
        key: "user-role-access",
        label: "user_role_access",
        icon: "",
        module : "settings",
        permission_required : true,
      },
      {
        key: "designations",
        label: "designations",
        icon: "",
        module : "settings",
        permission_required : true,
      },
      {
        key: "departments",
        label: "departments",
        icon: "",
        module : "settings",
        permission_required : true,
      },
    ],
  },
  {
    key : "support",
    label : "support",
    icon : <FontAwesomeIcon icon={faUsers}/>,
    permission_required : false,
    is_root : true,
    children : [
      {
        key: "chat",
        label: "chat",
        icon: "",
        module : "support",
        permission_required : false,
      },
    ]
  }
];

export default sidebar_options;
